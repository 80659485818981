import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { Link } from "react-router-dom";
import ProgressCircle from "../../components/ProgressCircle";
import StatBox from "../../components/StatBox";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAqC6e4Iy_tkuIag6tCWWIoSPybVxXdDvc",
    authDomain: "tnirb-85a64.firebaseapp.com",
    projectId: "tnirb-85a64",
    storageBucket: "tnirb-85a64.appspot.com",
    messagingSenderId: "1068096451733",
    appId: "1:1068096451733:web:fc03f7c0859d22667ed79c",
    measurementId: "G-8ZJ5W0QEYN"
  };
  
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    var uid = "";
    if (auth.currentUser) {
      uid = auth.currentUser.uid;
    }

    const userRef = firestore.collection('users');
    var memberData = {};
    const [docs, loadingDocs] = useCollectionData(userRef);

    if (docs) { 
      for (let i = 0; i < docs.length; i++) {
        if (docs[i].uid == uid) {
          memberData = docs[i];
          break
        } 
      }
    }

    return <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title={"Hello, "+( loadingDocs ? "null" : memberData.rank )} subtitle="Summary & Weekly Analytics"/>
        
            <Box>
                <Link to="/submit_event">
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        Submit Event
                    </Button>
                </Link>
            </Box>
        </Box>
        <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
        >
            <Box gridColumn="span 6" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                <StatBox 
                    title="Quota Check" 
                    subtitle="Incomplete" 
                    progress={0.50} 
                    icon={
                        <TrackChangesOutlinedIcon
                            sx={{ color: colors.greenAccent[600], fontSize: "26px"}}
                        />
                    }
                    increase="1 / 2 Points"
                />
            </Box>
            <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                <StatBox 
                    title="12" 
                    subtitle="Events Hosted" 
                    progress={0.2} 
                    icon={
                        <LocalActivityOutlinedIcon
                            sx={{ color: colors.greenAccent[600], fontSize: "26px"}}
                        />
                    }
                    increase="20%"
                />
            </Box>
            <Box gridColumn="span 3" backgroundColor={colors.primary[400]} display="flex" alignItems="center" justifyContent="center">
                <StatBox 
                    title="69" 
                    subtitle="Lorems Ipsum'd" 
                    progress={0.75} 
                    icon={
                        <QuestionMarkOutlinedIcon
                            sx={{ color: colors.greenAccent[600], fontSize: "26px"}}
                        />
                    }
                    increase="75%"
                />
            </Box>
        </Box>
    </Box>
};

export default Dashboard;