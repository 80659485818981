import { Box, Button, TextField, Alert, AlertTitle, useTheme } from "@mui/material";
import { useState } from "react"
import Header from "../../components/Header";
import { tokens } from "../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAqC6e4Iy_tkuIag6tCWWIoSPybVxXdDvc",
    authDomain: "tnirb-85a64.firebaseapp.com",
    projectId: "tnirb-85a64",
    storageBucket: "tnirb-85a64.appspot.com",
    messagingSenderId: "1068096451733",
    appId: "1:1068096451733:web:fc03f7c0859d22667ed79c",
    measurementId: "G-8ZJ5W0QEYN"
  };
  
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

const ExemptionReq = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const exemptionsRef = firestore.collection('exemptions');

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [startDate, setSDValue] = useState(dayjs());
    const [endDate, setEDValue] = useState(dayjs());
    const [reason, setReason] = useState("");
    const [successVisible, setSuccess] = useState(false);

    const handleFormSubmit = async(e) => {
        e.preventDefault();
        const { uid } = auth.currentUser;

        await exemptionsRef.add({
            uid: uid,
            startDate: toString(startDate),
            endDate: toString(endDate),
            reason: reason,
            rejected: false,
        });

        setSuccess(true);
    };

    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box m="20px">
            <Header title="Exemption Request Form" subtitle="Submit an exemption notice to temporarily waive your quota." />
                { successVisible ? <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Your exemption has been submitted. You will be contacted if there are any issues regarding your request.
                </Alert> : undefined}
                <form onSubmit={handleFormSubmit}>
                    <Box 
                        display="grid" 
                        gap="30px" 
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4"}
                        }}
                        mt="15px"
                    >
                        <DatePicker
                            fullWidth
                            type="text"
                            label="Start Date"
                            onChange={(newValue) => {
                                console.log(newValue.$d.toUTCString())
                                setSDValue(newValue.$d.toUTCString())
                            }}
                            value={startDate}
                            sx={{ gridColumn: "span 2" }}
                            defaultValue={dayjs()}
                        />
                        <DatePicker
                            fullWidth
                            type="text"
                            label="End Date"
                            onChange={(newValue) => {
                                setEDValue(newValue.$d.toUTCString())
                            }}
                            value={endDate}
                            sx={{ gridColumn: "span 2" }}
                        />
                        <TextField
                            fullWidth
                            type="text"
                            label="Reason for Exemption"
                            onChange={(e) => {
                                setReason(e.target.value)
                            }}
                            value={reason}
                            sx={{ gridColumn: "span 4" }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="end" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Submit Exemption
                        </Button>
                    </Box>
                </form>
        </Box>
    </LocalizationProvider>
)};

export default ExemptionReq;