import { Box, Button, TextField, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";

const initialValues = {
    username: "",
    startDate: "",
    endDate: "",
    reason: "",

}

const userSchema = yup.object().shape({
    username: yup.string().required("required"),
    startDate: yup.string().required("required"),
    endDate: yup.string().required("required"),
    reason: yup.string().required("required"),
})

const EventSubmit = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const handleFormSubmit = (values) => {
        console.log(values);
    };

    return <Box m="20px">
        <Header title="Event Submission Form" subtitle="Submit events to count toward your quota" />
        <Formik 
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={userSchema}
        >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <Box 
                        display="grid" 
                        gap="30px" 
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4"}
                        }}
                    >
                        <TextField
                            fullWidth
                            varint="filled"
                            type="text"
                            label="Username"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.username}
                            name="username"
                            error={!!touched.username && !!errors.username}
                            helperText={touched.username && errors.username}
                            sx={{ gridColumn: "span 4" }}
                        />
                        <TextField
                            fullWidth
                            varint="filled"
                            type="text"
                            label="Start Date (MM/DD/YYYY)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.startDate}
                            name="startDate"
                            error={!!touched.startDate && !!errors.startDate}
                            helperText={touched.startDate && errors.startDate}
                            sx={{ gridColumn: "span 2" }}
                        />
                        <TextField
                            fullWidth
                            varint="filled"
                            type="text"
                            label="End Date (MM/DD/YYYY)"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.endDate}
                            name="endDate"
                            error={!!touched.endDate && !!errors.endDate}
                            helperText={touched.endDate && errors.endDate}
                            sx={{ gridColumn: "span 2" }}
                        />
                        <TextField
                            fullWidth
                            varint="filled"
                            type="text"
                            label="Reason for Exemption"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.reason}
                            name="reason"
                            error={!!touched.reason && !!errors.reason}
                            helperText={touched.reason && errors.reason}
                            sx={{ gridColumn: "span 4" }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="end" mt="20px">
                        <Button type="submit" color="secondary" variant="contained">
                            Submit Exemption
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    </Box>
}

export default EventSubmit;