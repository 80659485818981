import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";

import Profile from "../../components/Profile";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";


const Appbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [profileIsVisible, setProfileIsVisible] = useState(false);

    return (
    <Box>
        <Box display="flex" justifyContent="right" p={2}>
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton onClick={() => {setProfileIsVisible(!profileIsVisible)}}>
                    <PersonOutlinedIcon />
                </IconButton>
            </Box>
        </Box>
        {profileIsVisible && (
            <Profile />
        )}
    </Box>
    );
};

export default Appbar;