import { ColorModeContext, useMode, tokens, themeSettings } from './theme';
import { CssBaseline, ThemeProvider, Box, Button, Typography, TextField, CircularProgress, LinearProgress, useTheme } from '@mui/material';
import { Route, Routes } from 'react-router-dom'
import { useState } from 'react'

import Appbar from './scenes/global/Appbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
//import Members from './scenes/members';
//import Events from './scenes/events';
//import Schedule from './scenes/schedule';
//import Objectives from './scenes/objectives';
//import Directory from './scenes/directory';
import FAQ from './scenes/faq';
//import Contacts from './scenes/contacts';
import ExemptionReq from './scenes/exemption_form';
import EventSubmit from './scenes/submit_event';


// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/app-check';
import 'firebase/compat/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'

var reloadPage = false

// Project config
const firebaseConfig = {
  apiKey: "AIzaSyAqC6e4Iy_tkuIag6tCWWIoSPybVxXdDvc",
  authDomain: "tnirb-85a64.firebaseapp.com",
  projectId: "tnirb-85a64",
  storageBucket: "tnirb-85a64.appspot.com",
  messagingSenderId: "1068096451733",
  appId: "1:1068096451733:web:fc03f7c0859d22667ed79c",
  measurementId: "G-8ZJ5W0QEYN"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {
  const [theme, colorMode] = useMode();
  const [user, loadingAuth] = useAuthState(auth);

  var uid = "";
  if (auth.currentUser) {
    uid = auth.currentUser.uid;
  }

  const userRef = firestore.collection('users');
  var linked = false;
  const [docs, loadingDocs] = useCollectionData(userRef);

  if (docs) { 
    for (let i = 0; i < docs.length; i++) {
      if (docs[i].uid == uid) {
        linked = true;
        break
      } 
    }
  }

  console.log("linked? "+linked)

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          { loadingAuth || loadingDocs ? <Loading /> : !user ? <Login /> : !linked ? <AccountLink /> : <AppPage />}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const Loading = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box textAlign="center" alignItems="center" justifyContent="center">
      <Box 
        component="img"
        sx={{
          mt: "10%",
          height: "50%",
          width: "50%",
        }}
        alt="Reaper Battalion banner"
        src="https://media.discordapp.net/attachments/554701487864020993/855528535061954600/ReaperBanner.png?width=1440&height=476"
      />
      <Typography variant="h1" color={colors.grey[100]} fontWeight="bold" sx={{ mt: "10px", mb: "5px"}}>
        Nighthawk Reaper Battalion
      </Typography>
      <Typography variant="h2" color={colors.blueAccent[400]} mb="20px">
        Officer Corps Mainframe
      </Typography>
      <Box>
        <CircularProgress color='secondary' />
      </Box>
      <Box
        sx={{
          position: "fixed",
          left: "0",
          bottom: "0",
          height: "100px",
          width: "100%",
          backgroundColor: colors.primary[400],
        }}
      >

      </Box>
    </Box>
  )
}

const AppPage = () => {
  return (
    <div className="app">
      <Sidebar />
      <main className="content">
        <Appbar />
        <Routes>
          <Route exact path="/" element={<Dashboard/>} />
          <Route path="/faq" element={<FAQ/>} />
          <Route path="/exemption_form" element={<ExemptionReq/>} />
          <Route path="/submit_event" element={<EventSubmit/>} />
          {/*
          <Route path="/members" element={<Members />} />
          <Route path="/events" element={<Events />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/objectives" element={<Objectives />} />
          <Route path="/directory" element={<Directory />} />
          <Route path="/contact" element={<Contacts />} />
          */}
        </Routes>
      </main>
    </div>
  )
}

const AccountLink = () => {
  if (reloadPage) { window.location.reload() };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const userRef = firestore.collection('users');

  const [value, setValue] = useState("");

  var uid = "";
  if (auth.currentUser) {
    uid = auth.currentUser.uid;
  }

  const handleFormSubmit = async(e) => {
    e.preventDefault()
    if (userRef.doc(value)) {
      await userRef.doc(value).update({uid: uid,})
    }
  };

  return (
    <Box textAlign="center" alignItems="center" justifyContent="center">
      <Box 
        component="img"
        sx={{
          mt: "10%",
          height: "50%",
          width: "50%",
        }}
        alt="Reaper Battalion banner"
        src="https://media.discordapp.net/attachments/554701487864020993/855528535061954600/ReaperBanner.png?width=1440&height=476"
      />
      <Typography variant="h1" color={colors.grey[100]} fontWeight="bold" sx={{ mt: "10px", mb: "5px"}}>
        Nighthawk Reaper Battalion
      </Typography>
      <Typography variant="h2" color={colors.blueAccent[400]} mb="20px">
        Officer Corps Mainframe
      </Typography>
      <Typography color={colors.grey[100]} mb="20px">
        Enter your account link code below.<br/>Don't have a code? DM Primithium for a new one.
      </Typography>
      <Box>
        <form onSubmit={handleFormSubmit}>
          <TextField
            variant='standard'
            type="text"
            label="Link Code"
            onChange={(e) => {
                setValue(e.target.value);
            }}
            value={value}
            sx={{
              mr: "20px",
            }}
          />
          <Button type="submit" 
            color="secondary" 
            variant="contained" 
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            Link Account
          </Button>
          <Button 
          onClick={() => auth.signOut()}
          sx={{
            width: "100px",
            backgroundColor: colors.redAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            ml: "10px",
          }}
          >
            Logout
          </Button>
        </form>
      </Box>
      <Box
        sx={{
          position: "fixed",
          left: "0",
          bottom: "0",
          height: "100px",
          width: "100%",
          backgroundColor: colors.primary[400],
        }}
      >

      </Box>
    </Box>
  )
};

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
    reloadPage = true;
  }

  return (
    <Box textAlign="center" alignItems="center" justifyContent="center">
      <Box 
        component="img"
        sx={{
          mt: "10%",
          height: "50%",
          width: "50%",
        }}
        alt="Reaper Battalion banner"
        src="https://media.discordapp.net/attachments/554701487864020993/855528535061954600/ReaperBanner.png?width=1440&height=476"
      />
      <Typography variant="h1" color={colors.grey[100]} fontWeight="bold" sx={{ mt: "10px", mb: "5px"}}>
        Nighthawk Reaper Battalion
      </Typography>
      <Typography variant="h2" color={colors.blueAccent[400]} mb="20px">
        Officer Corps Mainframe
      </Typography>
      <Box>
        <Button onClick={signInWithGoogle} sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
        }}
        >
          Login
        </Button>
      </Box>
      <Box
        sx={{
          position: "fixed",
          left: "0",
          bottom: "0",
          height: "100px",
          width: "100%",
          backgroundColor: colors.primary[400],
        }}
      >

      </Box>
    </Box>
  )
}

export default App;
