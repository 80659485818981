import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { Link } from "react-router-dom";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAqC6e4Iy_tkuIag6tCWWIoSPybVxXdDvc",
    authDomain: "tnirb-85a64.firebaseapp.com",
    projectId: "tnirb-85a64",
    storageBucket: "tnirb-85a64.appspot.com",
    messagingSenderId: "1068096451733",
    appId: "1:1068096451733:web:fc03f7c0859d22667ed79c",
    measurementId: "G-8ZJ5W0QEYN"
  };
  
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();

const Profile = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    var uid = "null"

    if (auth.currentUser) {
        uid = auth.currentUser.uid
    }

    return (
        <Box
            zIndex="10"
            position="absolute"
            sx={{
                backgroundColor: colors.altBG,
                color: colors.grey[100],
                padding: "10px 20px",
                borderRadius: 1,
            }}
            right="0"
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Link to="/exemption_form">
                    <Button sx={{
                        width: "200px",
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                    >
                        Request Exemption
                    </Button>
                </Link>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt="5px">
                <Button 
                onClick={() => auth.signOut()}
                sx={{
                    width: "200px",
                    backgroundColor: colors.redAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                }}
                >
                    Logout
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt="2px">
                <Typography sx={{
                    fontSize:"9px",
                    mt: "5px",
                }}>
                    {uid}
                </Typography>
            </Box>
        </Box>
    );
}

export default Profile;

/*
import { useState } from "react";
import { Sidebar, Menu, MenuItem} from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme"; 
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";

const Item = ({title, to, icon, selected, setSelected}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
            <MenuItem
                active={selected === title}
                style={{
                    color: colors.grey[100],
                }}
                onClick={() => setSelected(title)}
                icon={icon}
                component={<Link to={to}/>}
            >
                <Typography>{title}</Typography>
            </MenuItem>
    );
};

const PageSidebar = (name, role) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");

    return (
        <Box display="flex" height="100%" minHeight="400px">
            <Sidebar 
                collapsed={isCollapsed}
                backgroundColor={colors.altBG}
                rootStyles={{
                borderRightStyle:"none"
            }}>
                <Menu iconShape="square" 
                menuItemStyles={{
                    button: {
                        "&": {
                            padding: "5px 35px 5px 20px"
                        },
                        "&.active": {
                            backgroundColor: "#6870fa"
                        },
                        "&:hover": {
                            backgroundColor: "#868dfb"
                        },
                    }
                }}>
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                                <Typography variant="h4" color={colors.grey[100]}>
                                    Reaper Battalion
                                </Typography>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile user"
                                    width="100px"
                                    height="100px"
                                    src="https://cdn.discordapp.com/avatars/337437326902820864/0f515bbbe9df5027a0be283be5b0141c.png?size=4096"
                                    style={{ cursor: "pointer", borderRadius: "50%"}}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h3"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >
                                    Primithium
                                </Typography>
                                <Typography variant="h5" color={colors.blueAccent[500]}>
                                    Branch Manager
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box>
                        <Item
                            title="Dashboard"
                            to={`/`}
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            View
                        </Typography>
                        <Item
                            title="Members"
                            to="/members"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Events"
                            to="/events"
                            icon={<TimelineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Schedule"
                            to="/schedule"
                            icon={<EventOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 0 5px 20px" }}
                        >
                            Help
                        </Typography>
                        <Item
                            title="Directory"
                            to="/directory"
                            icon={<DescriptionOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="FAQ"
                            to="/faq"
                            icon={<HelpOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Contacts"
                            to="/contacts"
                            icon={<ChatBubbleOutlineOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    );
};

export default PageSidebar;
*/